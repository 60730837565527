import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

const DocLink = ({fileName, children }) => (
      <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `2rem`,
        marginBottom: `2rem`,
      }} href={`/docs/${fileName}.pdf`}>{children}</a>
)


const ChristmasIndexPage = props => (
  <EventLayout pageTitle="Christmas in Claxton">
    <h2>17th Annual Christmas in Claxton Celebration and 4th Annual Fruitcake Bake-Off
Saturday, December 7, 2025 – Information Will Be Posted Soon </h2>
    {/* --
    <h2>16th Annual Christmas in Claxton Celebration – Saturday, December 2, 2023, 4:00 pm – 8:00 pm</h2>
    <h3>South Newton Street, Downtown Claxton </h3>< br/>

    <p>
      Christmas in Claxton offers an old-fashioned Christmas Celebration, sponsored by the City of Claxton 
      Downtown Development Authority.  Festivities include the Festival of Trees – a showcase of holiday trees 
      beautifully decorated for the season on display at Claxton City Hall, Parade of Lights, live entertainment, 
      arts and craft vendors, food vendors, and a visit from Santa and Mrs. Claus. 
    </p><br /> 

    <div css={{margin: `2rem 0`}}>
      <DocLink fileName="2023-Parade-of-Lights-Application">2023 16th Annual Parade of Lights Application and Guidelines</DocLink>
      <DocLink fileName="2023-Christmas-in-Claxton-Vendor">2023 Christmas Vendor Application</DocLink>
      <DocLink fileName="2023-Fruitcake-Bake-Off">2023 Fruitcake Bake Off - Flyer, Rules, & Registration Form</DocLink>
      <DocLink fileName="2023-Festival-of-Trees">2023 Festival of Trees - Flyer & Application</DocLink>
    </div>
-- */}

  </EventLayout>
)

export default ChristmasIndexPage
